import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';

@Component({
  selector: 'app-payment-message',
  templateUrl: './payment-message.component.html',
  styleUrls: ['./payment-message.component.css']
})
export class PaymentMessageComponent implements OnInit {

  paymentMessageList: []
  isLoading: boolean = false;
  paymentMessageAllList: [];
  isAllViews: boolean = false;
  constructor(
    private billInfoService: BillInformationService,
    public app:AppComponent,
    private router : Router
  ) { 

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }  }

  ngOnInit() {
    this.getPaymentMessage(1)

  }


  getPaymentMessage(id =1) {
    this.isLoading =true
    const lawyerCode = localStorage.getItem('loggedin_lawyercode');
    if (lawyerCode) {
      this.billInfoService.getPayment_Message(+lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
          // this.paymentMessageList = data;
          
          
           if (data && data.length > 10) {
            this.paymentMessageList = data.slice(0, 10);
            this.paymentMessageAllList = data
           
          } else {
             this.paymentMessageList = data
           }
          this.isAllViews = false
          this.isLoading = false
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      })
    }
  }



  getBillInfoAllData() {
    this.paymentMessageAllList.slice(10).forEach(item => this.paymentMessageList.push(item));
    this.isAllViews = true
  }
}
