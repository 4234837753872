import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppellatedivisionService } from "../../../appellatedivision.service";
import { LawyerinfoService } from "../../../lawyerinfo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { SimpleModalService } from "ngx-simple-modal";
import { AlertComponent } from "src/app/alert/alert.component";
import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { HtmlContentRenderModal } from "src/app/alert/htmlContentRender";

@Component({
  selector: "app-search-list-by-date-appelate",
  templateUrl: "./search-list-by-date-appelate.component.html",
  styleUrls: ["./search-list-by-date-appelate.component.css"],
})
export class SearchListByDateAppelateComponent implements OnInit, AfterViewInit {
  location: any = null;
  adminBdlawserviceFile = environment.adminBdlawserviceFile;
  @ViewChild('inputField') inputField!: ElementRef<any>;
  todaysdata: any = [];
  lawyerinfodata: any = [];
  previous_results: any = [];
  previous_results_datatime: any;
  previous_date: any;

  lawyerCode: any;
  maxDate: any;
  rangeDate: any;
  searchDate: Date = new Date();

  input_lawyer_code: string;

  errorMessage: string;

  is_draft: number;

  isLoading: boolean = false;

  detailsdata: boolean = false;
  isLoadingImage: boolean = false;

  SuCMessage: boolean = true;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  current_date: string;
  current_username: string;
  current_laywerCode: string;

  lawyergroupdata: any = [];

  searchlistnews: any = [];

  is_admin: boolean = false;
  current_lawyerType: string;

  isBlockSearchList: boolean = false;
  isDue: any;
  isDueMgs: any;
  isDueModal: any;
  isADNotFound: boolean = false;
  isADNotLayerFound: boolean = false;
  isTodayCaseFound: boolean = false;
  remarkPermission: number = 0;
  searchListBlockStatus: any;
  constructor(
    public rest: AppellatedivisionService,
    public lawyerinfo_api: LawyerinfoService,
    private SimpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private sanitizer: DomSanitizer
  ) {
    if (!this.app.loggedIn()) {
      this.router.navigate(["../"]);
    }


  }

  ngOnInit() {
    this.getAdMaxDate();
    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin();

    this.current_lawyerType = this.app.getLawyerType();

    this.current_date = formatDate(new Date(), "dd-MM-yyyy", "en");
    //console.log(this.maxDate);
    if (this.maxDate != null) {
      this.current_date = this.maxDate;
      this.searchDate = this.maxDate;
    }
    if (!this.is_admin) {
      this.getLawyerInfoSadmin();

      this.getLawyerGroup();
    }

    this.getsearchlistnews();

    this.getSearchListBlockStatus();
    let oldDate = localStorage.getItem("oldDateAd");
    let oldDateAdId = localStorage.getItem("oldDateAdId");
    const dateOld = new Date(oldDate);
    const currentDate = new Date(this.current_date);

    if (dateOld < currentDate || oldDateAdId != this.current_laywerCode) {
      this.getLawyerPaymentIsDue(oldDate, oldDateAdId);
    } else {
      this.isDue = 0;
      this.isDueMgs = "";
      this.isDueModal = false;
    }
    //  if(this.isDueModal) {
    //           let htmlCo=this.sanitizer.bypassSecurityTrustHtml(this.isDueMgs)
    // this.SimpleModalService.addModal(AlertComponent, { title: 'Due Massage', message: this.isDueMgs });
    //     }

    this.getRemark()
  }


  getRemark() {


    this.lawyerinfo_api.getRemarkPermission({
      lawyer_code: this.lawyerCode || this.current_laywerCode
    }).subscribe({
      next: (res) => {
        this.remarkPermission = Number(res.results[0].is_permission)
        console.log('remarkPermission', this.remarkPermission)
      },
      error(err) {
        console.error('something wrong occurred: ' + err);
      },
    })
  }



  pdfPrint() {
    // console.log(this.lawyergroupdata)
    // return
    setTimeout(() => {
      if (!this.isLoadingImage) {


        const casekey = this.todaysdata
          .map(player => `
                  <tr style="border: 1px solid red">
              <td>
                ${player.ad_court_list && player.ad_court_list.court_name != null ? player.ad_court_list.court_name : ''}
              </td>
              <td style="text-align: center">
                ${player.page_no != null ? player.page_no : ''}
              </td>
              <td style="text-align: center">
                ${player.dout != null ? player.dout : ''} ${player.sl != null ? player.sl : ''}
              </td>
              <td style="text-align: left; font-size: 17px">
                ${player.ad_case_type && player.ad_case_type.type_name != null ? player.ad_case_type.type_name : ''}
              </td>
              <td style="text-align: right">
                ${player.case_no != null ? player.case_no : ''}${player.case_no_plus != null ? player.case_no_plus : ''}/${player.case_year != null ? player.case_year : ''}
              </td>
              <td style="font-size: 13px">
                ${player.ad_what_for && player.ad_what_for.title != null ? player.ad_what_for.title : ''}
              </td>
              <td style="font-size: 14px">
                ${player.pr == 1 ? 'Pet' : 'Res'}.
              </td>
              <td style="font-size: 10px">
                ${player.ad_parties_info && player.ad_parties_info.vs != null ? player.ad_parties_info.vs : ''}
              </td>
              ${this.remarkPermission == 1
              ? `<td style="font-size: 10px">${player.lawyer_case_wise_remark != null && player.lawyer_case_wise_remark.remarks != null
                ? player.lawyer_case_wise_remark.remarks
                : " "
              }</td>`
              : ''
            }
            </tr>

      `)
          .join("");

        const block = this.searchListBlockStatus.is_block_ad != 1 ?
          `<h3 style="margin-top:15px !important; font-weight:bold; font-size: 16px !important; text-align:center;">
          Case Results of Previous Day (${formatDate(
            this.previous_date[0].get_date,
            'dd/MM/yyyy',
            'en'
          )})
          </h3>
       <h4 style="margin-top:-10px !important; font-weight:100; font-size: 17px !important; text-align:center;">
          (Based on available results in Bangladesh Supreme Court’s website as on  ${formatDate(
            this.previous_results_datatime[0].last_datetime,
            'dd/MM/yyyy At hh:mm:ss a', 'en-US'
          )}  ) 
          </h4>
          
          
          `: ""


        const caseResults = this.previous_results.map((prevresss, index) => {
          // Only render a new row when the index is even (two results per row)
          if (index % 2 === 0) {
            return `
                <tr>
                 <td style="font-size: 15px">
                    ${prevresss && prevresss.type_name != null ? prevresss.type_name : ''} No.${prevresss && prevresss.case_no != null ? prevresss.case_no : ''}/${prevresss && prevresss.case_year != null ? prevresss.case_year : ''}
                  </td>
                  <td style="font-size: 15px">
                    ${prevresss && prevresss.result != null ? prevresss.result : ''}
                  </td>
                </tr>
              `;
          }
          return "";
        }).join("");
        const caseResultsEven = this.previous_results.map((prevresss, index) => {
          // Only render a new row when the index is even (two results per row)
          const nextItem = this.previous_results[index + 1];
          if (index % 2 !== 0) {
            return `
                <tr>
                 <td style="font-size: 15px">
                    ${prevresss && prevresss.type_name != null ? prevresss.type_name : ''} No.${prevresss && prevresss.case_no != null ? prevresss.case_no : ''}/${prevresss && prevresss.case_year != null ? prevresss.case_year : ''}
                  </td>
                  <td style="font-size: 15px">
                    ${prevresss && prevresss.result != null ? prevresss.result : ''}
                  </td>
                </tr>
              `;
          }
          return "";
        }).join("");
        // const casekeys =this.todaysdata.splice(0,3)
        // console.log(this.searchListBlockStatus)
        // return
        // console.log(casekey)
        // Generate the HTML for all pages
        const pageHtml = `
      <html>
        <head>
          <style>
            @page {
              width: 210mm;
              margin: 0.50inc;
              background: #fff;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
              page-break-before: always;
              
               @bottom-right {
        content: "Page " counter(page) " of " counter(pages);
        font-size: 12px;
      }

      @page :last {
      .page{
      background-color: 'red'
      }
}
            }
            body {
              font-family: Arial, sans-serif;
            }
            .header,
            .footer {
              text-align: center;
            }
            .pdf-table {
              width: 100%;
              border-collapse: collapse;
              
            }
            .pdf-table th,
            .pdf-table td {
              border: 1px solid #a9a5a5;
              padding: 4px;
              text-align: left;
             
            }
            .header-top {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 2px solid #000;
              padding: 0px 0;
              height: 31px;
            }
            .header-top .general-code {
              margin-left: 0px;
              color: #000;
              font-size: 20px;
            }
            .header-top .header-date {
              margin-right: 0px;
              color: #000;
            }
            table tr th {
              padding: 8px 12px;
              border: 1px solid #000;
              background-color: #dddddd;
              color: white;
              text-align: left;
              font-size: 18px;
              font-weight: 500;
              color: #000
            }
            table tbody tr td {
              padding: 8px 12px;
              border: 1px solid #ddd;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
              
            }
            // tr:nth-child(even) {
            //   background-color: #dddddd;
            // }
            .pages {
              width: 210mm;
              background: #fff;
              margin: 0 auto
            }
            .footer-design {
              display: flex;
              text-align: left;
              justify-content: space-between;
              line-height: 20px;
              margin-top: -6px;
              border-top: 1px solid #000;
              height: 35px;
            }
    
            @media print{
            body {
            counter-reset: page; 
            }
            .notice-show{
             page-break-inside: avoid;
            }
             .footer{
         
        page-break-inside: avoid;
             }

          }

          
          </style>
        </head>
        <body>
         <div class="pages">
                  <div class="header">
    
                <div style="margin-bottom: 20px;">
                <h1 style='font-size: 20px;font-weight: bold; border: 2px solid #000; width: 443px; margin: 0px auto; padding: 3px;'>Search List By Date for Appellate Division </h1>
                </div>
                    <div class="header-top">
                      <div>
                         <h2 class="general-code">${this.lawyergroupdata.lawyer_group == 1 ? 'General' : 'Special'}  Code: ${this.lawyergroupdata.lawyer_id
          } </h2>
                      </div>
                      <div>
                        <p>(Print Time: ${formatDate(
            new Date(),
            'dd-MM-yyyy',
            'en'
          )} At ${new Date().toLocaleTimeString()})</p>
                      </div>
                      <div>
                        <h4 class="header-date">Search List Date: ${this.current_date

          }</h4>
                      </div>
                    </div>
                   <div style='text-align: left; margin: 15px 0; 0 height : 90px' >
                      <h2 style='margin: 0; padding: 0; font-size: 20px'>${this.lawyerinfodata.lawyer_name
          }</h2>
                      <h5 style='margin: 0; padding: 8px 0px; font-size: 16px'>${this.lawyerinfodata.address
          }</h5>
      <p style='margin: 0; padding: 0'>${this.lawyerinfodata.phone
          }</p>
                      ${this.is_admin ? `  <p style='margin: 0; padding: 0'>${this.lawyerinfodata.mobile
            }</p>` : ""
          }
                    
                    </div>
    
                    ${this.is_draft == 1 ? `<div style='text-align: center; margin: 15px 0 ; border-top: 2px solid #000; color: #000 ; height: 20px' ><p style='margin: 0; padding: 2px; font-size: 16px; font-weight: bold; margin-top: 5'>  DRAFT COPY
                      </p>
    
                      
                    </div>` : " "
          }
               
                   
                  </div>
                  <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                    <thead>
                      <tr style="border-bottom: 2px solid #000; border-top: 2px solid #000">
                       <th style="width:100px;">Court</th>
                                <th style="width:43px; text-align:center;">Page</th>
                                <th style="width:64px; text-align:center;">Serial</th>
                                <th style="width:170px; padding-left:5px;">Case Type</th>
                                <th style="width:120px; padding-right:5px; text-align:right;">Case No.</th>
                                <th style="width:92px;">For</th>
                                <th style="width:27px; text-align:center;  font-size: 15px"> P/R </th>
                                <th style="width:200px; text-align:center;">Name of parties</th>
                                ${this.remarkPermission == 1 ? ' <th style="width: 110px !important ;">Remarks</th>' : ''
          }  
    
                      </tr>
    
                  
                    </thead>
                    <tbody>
                     ${casekey}
                    </tbody>
                  </table>
    
    
                <div style= "border-bottom: 1px solid #000; height: 30px; margin-top: -8px">
                <h3 >${this.todaysdata.length > 0 ? `Total Case Found :  ${this.todaysdata.length}` : `No items to display`}</h3>
                </div>
    
    ${block}
    
    
    
                  ${this.previous_results.length > 0 ? `
                    <div style='display: flex; align-items: 'center'>
                      <div style="width: 50%">   
                      <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                      <thead>
                          <tr style=" border-bottom: 2px solid #000; border-top: 2px solid #000">
                          <th style="width: 29%; padding: 5px; text-align: left;">Case Type & No.</th>
                          <th style="width: 21%; padding: 5px; text-align: center;">Result</th>
                        </tr>
                        </thead>

                        <tbody>
                      
                    ${caseResults} 
                        </tbody> 
                        </table>
                      </div>

                      <div style="width: 50%">
                      <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                      <thead>
                          <tr style=" border-bottom: 2px solid #000; border-top: 2px solid #000">
                          <th style="width: 29%; padding: 5px; text-align: left;">Case Type & No.</th>
                          <th style="width: 21%; padding: 5px; text-align: center;">Result</th>
                        </tr>
                        </thead>

                        <tbody>
                      ${caseResultsEven} 
                      
                        </tbody> 
                        </table>
                      </div>
                      </div>
                    `: `
                    <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                    <thead>
                    <tr style=" border-bottom: 2px solid #000; border-top: 2px solid #000">
                    <th style="width: 29%; padding: 5px; text-align: left;">Case Type & No.</th>
                    <th style="width: 21%; padding: 5px; text-align: center;">Result</th>
                
                    <th style="width: 29%; padding: 5px; text-align: left;">Case Type & No.</th>
                    <th style="width: 21%; padding: 5px; text-align: center;">Result</th>
                
                  </tr>
                  </thead>
                  <tbody>
                
                 <td colspan="4" style="padding: 4px 2px; font-weight : bold ; font-size: 20px">No result found</td>
                  </tbody>
                   </table>
                    `}
    
                      <div class ="notice-show" style="border: 1px solid #000; padding: 10px; text-align: center;  width: 650px; margin: 20px auto 0px;">
    <p style ="font-size: 22px">সামান্য অর্থের চেয়ে ১টি মামলার মূল্য অনেক অনেক বেশি, তাই <br/> আপনার মামলার নিরাপত্তার জন্য সার্চলিস্ট ব্যবহার অব্যাহত রাখুন।</p>
    
                      </div>
    
    
    
                  <div class="footer">
                  <div style="  margin: 0; padding: 0; ">
                    <p style ="text-align: left; font-size: 13px"><b>** বিশেষ অবহিতকরণঃ</b> কজলিস্ট বইয়ের ছাপার অস্পষ্টতাজনিত অথবা আমাদের অনিচ্ছাকৃত ভুলের কারণে কখনও কখনও কিছু মামলার সার্চ <br/>  রেজাল্টে ভুল আসতে পারে। সংশ্লিষ্ট সকলকে মূল কজলিস্ট/ওয়েবসাইট এর সাথে “মামলাগুলো রেজাল্টসহ” মিলিয়ে দেখার জন্য অনুরোধ করা হল।
                     </p>
                  </div>
                   <div class="footer-design">
                      <div style="padding-right: 32px;">
                        <p style="font-size: 14px; margin-top: 1px">Service Provide by : <span style="font-size: 14px; font-weight: bold; padding-right: 4;">SIDDIQUE ENTERPRISE </span> Room No. 45 and 60 (G.F), Phone: 01819-49 09 29, 01772-72 78 78
                       <br/> E-mail : bdlawservice@gmail.com, Web: <b>www.bdlawservice.com & www.bdcourt.com</b> </p>
                      </div>
                  
                    </div>
                  </div>
                </div>
        </body>
      </html>
      `;


        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        document.body.appendChild(iframe);

        const doc = iframe.contentWindow.document || iframe.contentDocument;
        if (!doc) {
          console.error('Failed to access iframe document.');
          return;
        }

        doc.open();
        doc.write(pageHtml)
        doc.close()

        iframe.onload = () => {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
        };
      }
    }, 500);

    // window.document.write(pageHtml)
  }
  private focusInterval: any;

  ngAfterViewInit(): void {

    const input = this.inputField.nativeElement;
    // console.log(input)
    // Set focus initially
    this.ensureFocus();

    // Continuously ensure focus
    this.focusInterval = setInterval(() => {
      this.ensureFocus();
    }, 1000); // Check every 500ms
  }

  private ensureFocus(): void {
    const input = this.inputField.nativeElement;
    if (document.activeElement !== input) {
      input.focus();
    }
  }

  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.focusInterval) {
      clearInterval(this.focusInterval);
    }
  }
  modalOpen() {
    if (this.isDueModal) {
      // let htmlCo=this.sanitizer.bypassSecurityTrustHtml(this.isDueMgs)

      this.SimpleModalService.addModal(HtmlContentRenderModal, {
        title: "বিল পরিশোধের অনুরোধ ।",
        message: this.isDueMgs,
      });
    }
  }
  getAdMaxDate() {
    this.rest.getADMaxd().subscribe((max) => {
      this.rangeDate = new Date(max);
      this.searchDate = new Date(max);
      this.getCasetoday();
    });
  }

  getCasetoday() {
    this.isTodayCaseFound = false;
    this.getSearchListBlockStatus();

    this.is_draft = 0;
    this.todaysdata = [];
    this.lawyerinfodata = [];
    this.previous_results = [];
    this.previous_results_datatime = [];
    this.previous_date = [];

    if (this.is_admin) {
      this.lawyergroupdata = [];
      this.input_lawyer_code = this.lawyerCode;

      if (
        this.input_lawyer_code === undefined ||
        this.input_lawyer_code == ""
      ) {
        this.errorMsg2 = true;
        // this.isLoading = true;
        return;
      } else if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg2 = false;
        this.errorMsg = true;
        // this.isLoading = true;
        return;
      }

      this.current_date = formatDate(this.searchDate, "dd-MM-yyyy", "en");
      this.errorMsg = false;
      this.errorMsg2 = false;

      this.getLawyerGroup();
    } else {
      this.input_lawyer_code = this.current_laywerCode;

      if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg = true;
        this.isLoading = false;
        return;
      }

      this.current_date = formatDate(this.searchDate, "dd-MM-yyyy", "en");
      this.errorMsg = false;
    }

    let postData = {
      lawyerCode: this.input_lawyer_code,
      searchDate: this.searchDate,
    };
  let postDataNew = {searchDate:formatDate(this.searchDate , "dd-MM-yyyy", "en")}
    this.getLawyerInfo();
    this.detailsdata = false;
    this.SuCMessage = false;
    this.isLoading = true;
    this.isTodayCaseFound = false;
    if (this.lawyergroupdata.ad_user != 0 && !this.isADNotLayerFound) {
      this.rest.getCasetoday(postData).subscribe(
        (todaysdata) => {
          // this.isTodayCaseFound  = todaysdata && todaysdata.length > 1 ? false : true;

          if (todaysdata.length > 0) {
            this.isTodayCaseFound = false;
          } else {
            this.isTodayCaseFound = true;
          }

          this.todaysdata = todaysdata;

          // console.log('today data', todaysdata);
          this.isLoading = false;
          this.errorMessage = "";
          this.isLoadingImage = false;
          this.detailsdata = true;
          this.SuCMessage = false;
          this.isLoading = false;
        },
        (error) => {
          this.errorMessage = <any>error;
          this.isLoading = false;
          this.isLoadingImage = false;
          this.SuCMessage = false;
          this.isTodayCaseFound = false;
        }
      );

      this.rest.getPreviousResult(postData).subscribe((previous_results) => {
        this.previous_results = previous_results;
      });
      
      this.rest
        .getPreviousResultDateTime(postDataNew)
        .subscribe((previous_results_datatime) => {
          this.previous_results_datatime = previous_results_datatime;
        });

      this.rest.getPreviousDate(postData).subscribe((previous_date) => {
        this.previous_date = previous_date;

      });
      // console.log('is draft',postData);
      this.rest.getDraftStatus(postData).subscribe((is_draft) => {
        this.is_draft = is_draft;
      
     
      });
    }
  }

  getLawyerInfo() {
    let postData = {
      lawyerCode: this.input_lawyer_code,
    };

    this.lawyerinfo_api.getLawyerinfo(postData).subscribe(
      (lawyerinfodata) => {
        this.lawyerinfodata = lawyerinfodata;
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  getCasetodayDataForm() {
    if (this.current_laywerCode !== 'sadmin') {
      this.getDueMessage()
    }
    this.getCasetoday()
  }
  getDueMessage() {
    let postData = {
      l_id: this.current_laywerCode,
    };
    this.lawyerinfo_api.getLawyerPaymentIsDue(postData).subscribe(
      (resIsDue) => {
        if (resIsDue.isDue == 1) {

          this.SimpleModalService.addModal(HtmlContentRenderModal, {
            title: "বিল পরিশোধের অনুরোধ ।",
            message: resIsDue.message,
          });
        }
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    )
  }
  getLawyerPaymentIsDue(oldDate, oldDateHdId) {
    // let oldDate = localStorage.getItem("oldDateHd");
    //   let oldDateHdId = localStorage.getItem("oldDateHdId");
    let postData = {
      l_id: this.current_laywerCode,
    };

    this.lawyerinfo_api.getLawyerPaymentIsDue(postData).subscribe(
      (resIsDue) => {
        console.log('due message', resIsDue.message);
        this.isDue = resIsDue.isDue;
        this.isDueMgs = resIsDue.message;
        if (resIsDue.isDue == 1) {
          this.isDueModal = true;
        } else {
          this.isDueModal = false;
        }

        if (resIsDue.isDue == 1) {
          // alert(resIsDue.message);
          localStorage.setItem("oldDateAd", this.current_date);
          localStorage.setItem("oldDateAdId", this.current_laywerCode);
        } else if (oldDateHdId != this.current_laywerCode) {
          localStorage.removeItem("oldDateAdId");
          localStorage.removeItem("oldDateAd");
        }
        this.modalOpen();
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  getLawyerInfoSadmin() {
    let postData = {
      lawyerCode: this.input_lawyer_code,
    };

    this.lawyerinfo_api.getLawyerInfoSadmin(postData).subscribe(
      (lawyerinfodata) => {
        this.lawyerinfodata = lawyerinfodata;
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  getLawyerGroup() {
    this.isADNotFound = false;
    this.isADNotLayerFound = false;
    this.isTodayCaseFound = false;
    this.isLoading = true;
    this.isTodayCaseFound = false;

    // this.inputField.nativeElement.focus();
    let postData = {
      lawyerCode: this.is_admin
        ? this.input_lawyer_code
        : this.current_laywerCode,
    };

    this.lawyerinfo_api.getLawyerGroup(postData).subscribe(
      (lawyergroupdata) => {
        if (lawyergroupdata.hasOwnProperty("ad_user")) {
          this.lawyergroupdata = lawyergroupdata;

          if (lawyergroupdata.ad_user != 0) {
            this.getLawyerInfoSadmin();
          } else {
            this.isADNotFound = true;
          }
        } else {
          this.lawyergroupdata = {
            ad_user: 1,
          };
          this.isADNotLayerFound = true;
        }

        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
        this.isADNotLayerFound = true;
      }
    );
  }

  getsearchlistnews() {
    let postData = {};
    //console.log(postData);

    this.lawyerinfo_api.getsearchlistnews(postData).subscribe(
      (searchlistnews) => {
        // console.log(courtwisedata);
        this.searchlistnews = searchlistnews;
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }

  getSearchListBlockStatus() {
    let postData = {
      id: 2,
    };

    this.lawyerinfo_api.getSearchListBlockStatus(postData).subscribe(
      (isBlockSearchList) => {

        (this.isBlockSearchList = isBlockSearchList), (this.isLoading = false);
        // console.log("==== isBlockSearchList" ,  isBlockSearchList);
        this.searchListBlockStatus = isBlockSearchList
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }
}
