import { Component, OnInit } from '@angular/core';
import { CasetodayService } from '../../../casetoday.service';
import { LawyerinfoService } from '../../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { SimpleModalService } from 'ngx-simple-modal';
import { HtmlContentRenderModal } from 'src/app/alert/htmlContentRender';

@Component({
  selector: 'app-search-list-by-date-highcourt',
  templateUrl: './search-list-by-date-highcourt.component.html',
  styleUrls: ['./search-list-by-date-highcourt.component.css']
})
export class SearchListByDateHighcourtComponent implements OnInit {
  adminBdlawserviceFile = environment.adminBdlawserviceFile
  location: any = null;

  todaysdata: any = [];
  lawyerinfodata: any = [];
  previous_results: any = [];
  previous_results_datatime: any;
  previous_date: any;
  rangeDate: any;
  lawyerCode: any;
  searchDate: Date = new Date();

  input_lawyer_code: string;

  errorMessage: string;

  is_draft: number;
  maxDate: any = null;
  isLoading: boolean = true;

  detailsdata: boolean = false;
  isLoadingImage: boolean = false;

  SuCMessage: boolean = true;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  current_date: string;
  current_username: string;
  current_laywerCode: string;

  lawyergroupdata: any = [];

  searchlistnews: any = [];

  is_admin: boolean = false;
  current_lawyerType: string;

  isBlockSearchList: boolean = false;
  isDue: any;
  isDueMgs: any;
  isDueModal: any;
  constructor(
    public rest: CasetodayService,
    public lawyerinfo_api: LawyerinfoService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private sanitizer: DomSanitizer,
    private SimpleModalService: SimpleModalService
  ) {

    if (!this.app.loggedIn()) {
      //this.location.replaceState('/');
      this.router.navigate(['../']);
    }

  }

  ngOnInit() {

    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.input_lawyer_code = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin();

    this.current_lawyerType = this.app.getLawyerType();

    this.current_date = formatDate(new Date(), 'dd-MM-yyyy', 'en');

    if (this.maxDate != null) {
      this.current_date = this.maxDate;
      this.searchDate = this.maxDate;
    }
    if (!this.is_admin) {
      this.getLawyerInfoSadmin();

      this.getLawyerGroup();
    }


    this.getsearchlistnews();



    this.getSearchListBlockStatus();
    this.getHdMaxDate();
    let oldDate = localStorage.getItem("oldDateHd");
    let oldDateHdId = localStorage.getItem("oldDateHdId");
    const dateOld  = new Date(oldDate)
    const currentDate  = new Date(this.current_date)
    if (dateOld < currentDate || oldDateHdId != this.current_laywerCode) {
      this.getLawyerPaymentIsDue(oldDate, oldDateHdId);
    } else {
      this.isDue = 0; this.isDueMgs = ""; this.isDueModal = false;
    }
  }




  getLawyerPaymentIsDue(oldDate, oldDateHdId) {
    // let oldDate = localStorage.getItem("oldDateHd");
    //   let oldDateHdId = localStorage.getItem("oldDateHdId");
    let postData = {
      l_id: this.current_laywerCode
    }

    this.lawyerinfo_api
      .getLawyerPaymentIsDue(postData)
      .subscribe(
        resIsDue => {
          console.log(resIsDue)

          //  alert(resIsDue.message);
          this.isDue = resIsDue.isDue;
          this.isDueMgs = resIsDue.message;
          if (resIsDue.isDue == 1) {
            this.isDueModal = true
            let htmlCo = this.sanitizer.bypassSecurityTrustHtml(this.isDueMgs)
            this.SimpleModalService.addModal(HtmlContentRenderModal, { title: 'বিল পরিশোধের অনুরোধ । ', message: resIsDue.message });
          }
          else { this.isDueModal = false }


          if (resIsDue.isDue == 1) {
            // alert(resIsDue.message);
            localStorage.setItem("oldDateHd", this.current_date);
            localStorage.setItem("oldDateHdId", this.current_laywerCode);


          } else if (oldDateHdId != this.current_laywerCode) {

            localStorage.removeItem("oldDateHdId");
            localStorage.removeItem("oldDateHd");

          }

        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }
  getHdMaxDate() {
    this.rest
      .getHDMaxd()
      .subscribe(
        max => {

          this.rangeDate = new Date(max);
          this.searchDate = new Date(max);
          this.getCasetoday();


        }
      );

  }
  getCasetoday() {

    this.getSearchListBlockStatus();

    this.is_draft = 0;
    this.todaysdata = [];
    this.lawyerinfodata = [];
    this.previous_results = [];
    this.previous_results_datatime = [];
    this.previous_date = [];

    if (this.is_admin) {

      this.lawyergroupdata = [];
      this.input_lawyer_code = this.lawyerCode;

      if (this.input_lawyer_code === undefined || this.input_lawyer_code == "") {
        this.errorMsg2 = true;
        this.isLoading = true;
        return;
      } else if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg2 = false;
        this.errorMsg = true;
        this.isLoading = true;
        return;
      }

      this.current_date = formatDate(this.searchDate, 'dd-MM-yyyy', 'en');
      this.errorMsg = false;
      this.errorMsg2 = false;

      this.getLawyerGroup();

    } else {

      this.input_lawyer_code = this.current_laywerCode;

      if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg = true;
        this.isLoading = true;
        return;
      }

      this.current_date = formatDate(this.searchDate, 'dd-MM-yyyy', 'en');
      this.errorMsg = false;
    }

    let postData = {
      lawyerCode: this.input_lawyer_code,
      searchDate: this.searchDate,
    }
    if (postData.searchDate.getFullYear() == new Date().getFullYear()) {
      this.rest
        .getDraftStatus(postData)
        .subscribe(
          is_draft => {
            this.is_draft = is_draft
          }
        );
    }

    this.getLawyerInfo();

    this.isLoadingImage = true
    this.detailsdata = false

    this.SuCMessage = false;

    //console.log(postData);

    this.rest
      .getCasetoday(postData)
      .subscribe(
        todaysdata => {
          this.todaysdata = todaysdata
          this.isLoading = false
          this.errorMessage = ""
          this.isLoadingImage = false
          this.detailsdata = true
          this.SuCMessage = false
          //console.log(this.todaysdata);
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
          this.isLoadingImage = false
          this.SuCMessage = false
        }
      );

    this.rest
      .getPreviousResult(postData)
      .subscribe(
        previous_results => {
          this.previous_results = previous_results
        }
      );

    this.rest
      .getPreviousResultDateTime(postData)
      .subscribe(
        previous_results_datatime => {
          this.previous_results_datatime = previous_results_datatime
        }
      );

    this.rest
      .getPreviousDate(postData)
      .subscribe(
        previous_date => {
          this.previous_date = previous_date

        }
      );


  }

  getLawyerInfo() {
    let postData = {
      lawyerCode: this.input_lawyer_code
    }

    this.lawyerinfo_api
      .getLawyerinfo(postData)
      .subscribe(
        lawyerinfodata => {
          this.lawyerinfodata = lawyerinfodata
          this.isLoading = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }

  getLawyerInfoSadmin() {
    let postData = {
      lawyerCode: this.input_lawyer_code
    }

    this.lawyerinfo_api
      .getLawyerInfoSadmin(postData)
      .subscribe(
        lawyerinfodata => {
          this.lawyerinfodata = lawyerinfodata
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  getLawyerGroup() {

    let postData = {
      lawyerCode: this.is_admin ? this.input_lawyer_code : this.current_laywerCode
    }

    //alert(this.input_lawyer_code)

    this.lawyerinfo_api
      .getLawyerGroup(postData)
      .subscribe(
        lawyergroupdata => {
          this.lawyergroupdata = lawyergroupdata
          console.log(this.lawyergroupdata)
          this.isLoading = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }


  getsearchlistnews() {
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
      .getsearchlistnews(postData)
      .subscribe(
        searchlistnews => {
          // console.log(courtwisedata);
          this.searchlistnews = searchlistnews
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }


  getSearchListBlockStatus() {
    let postData = {
      id: 1
    }

    this.lawyerinfo_api
      .getSearchListBlockStatus(postData)
      .subscribe(
        isBlockSearchList => {
          this.isBlockSearchList = isBlockSearchList,
            this.isLoading = false
          console.log("====" + isBlockSearchList[0].is_block_hd);
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }


}
