import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';

@Component({
  selector: 'app-bill-submit-message',
  templateUrl: './bill-submit-message.component.html',
  styleUrls: ['./bill-submit-message.component.css']
})
export class BillSubmitMessageComponent implements OnInit {
  billInfoDataList: []
  isLoading: boolean = false;
  constructor(
    private billInfoService: BillInformationService,
    public app: AppComponent,
    private router: Router
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit() {
    this.getNotifications_payment_newRo(0)

  }


  getNotifications_payment_newRo(id = 0) {
    this.isLoading = true
    const lawyerCode = localStorage.getItem('loggedin_lawyercode');
    if (lawyerCode) {
      this.billInfoService.getNotifications_payment_newRo(+lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
          this.billInfoDataList = data;
          this.isLoading = false
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false
        }
      })
    }
  }
}
