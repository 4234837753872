import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';


const endpointOther = environment.endpointOther;

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
@Injectable({
  providedIn: 'root'
})
export class BillInformationService {

  
  constructor(private httpClient: HttpClient) {}


  getNotifications_payment_newRo(l_id: any, id:number):Observable<any>{
    return this.httpClient.get<any>(`${endpointOther}getNotifications_payment_newRo?l_id=${l_id}&id=${id}&message_type=Due_Message`,  httpOptions);
  }


  payment_Reminder_Message(l_id: any, id: number):Observable<any>{
    return this.httpClient.get<any>(`${endpointOther}getNotifications_payment_newRo?l_id=${l_id}&id=${id}&message_type=Payment_Reminder_Message`,  httpOptions);
  }
  getPayment_Message(l_id: any, id: number):Observable<any>{
    return this.httpClient.get<any>(`${endpointOther}getNotifications_payment_newRo?l_id=${l_id}&id=${id}&message_type=Payment_Message`,  httpOptions);
  }
}
