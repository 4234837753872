import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-bill-submit-message-sadmin',
  templateUrl: './bill-submit-message-sadmin.component.html',
  styleUrls: ['./bill-submit-message-sadmin.component.css']
})
export class BillSubmitMessageSadminComponent implements OnInit {
  lawyerCode: number
  billInfoDataList: []
  isLoading: boolean = false;
  IsValidationErrorMessageShown = false; //:
  lawyerinfodata: any
  searchDate: any
    billInfoAllData: []
  isAllViews: boolean = false
  constructor(private billInfoService: BillInformationService, private lawyerinfo_api: LawyerinfoService,
    public app: AppComponent,
    private router: Router
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit() {
    this.searchDate = new Date();

  }



  getLawyerInfo() {
    this.isLoading = true;
    let postData = {
      lawyerCode: this.lawyerCode
    }
    this.lawyerinfo_api
      .getLawyerinfo(postData)
      .subscribe({
        next: (data) => {
          // console.log(data);
          this.isLoading = false
          this.lawyerinfodata = data
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false
        }
      })
  }

  getNotifications_payment_newRo(id: number) {
    this.billInfoDataList = []
    if (!this.lawyerCode && typeof this.lawyerCode !== 'number') {
      this.IsValidationErrorMessageShown = true;
      return
    }
    this.isLoading = true
    if (this.lawyerCode) {
      this.billInfoService.getNotifications_payment_newRo(this.lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);

          // this.billInfoDataList = data;
       
          this.getLawyerInfo()

            if (data.length >= 10) {
            this.billInfoDataList = data.slice(0, 10);
              this.billInfoAllData = data
            }
           
          if (data && data.length <= 10) {
            this.billInfoDataList = data
          }
             this.isAllViews = false;
             this.isLoading = false
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false
        }
      })
    }
  }
 getBillInfoAllData() {
    this.billInfoAllData.slice(10).forEach(item => this.billInfoDataList.push(item));
    this.isAllViews = true
  }
}
