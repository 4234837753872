import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from '../../../appellatedivision.service';
import { LawyerinfoService } from '../../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from 'src/app/alert/alert.component';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlContentRenderModal } from 'src/app/alert/htmlContentRender';

@Component({
  selector: 'app-search-list-by-date-appelate',
  templateUrl: './search-list-by-date-appelate.component.html',
  styleUrls: ['./search-list-by-date-appelate.component.css']
})
export class SearchListByDateAppelateComponent implements OnInit {

  location: any = null;
  adminBdlawserviceFile = environment.adminBdlawserviceFile
  todaysdata: any = [];
  lawyerinfodata: any = [];
  previous_results: any = [];
  previous_results_datatime: any;
  previous_date: any;

  lawyerCode: any;
  maxDate: any;
  rangeDate: any;
  searchDate: Date = new Date();

  input_lawyer_code: string;

  errorMessage: string;

  is_draft: number;

  isLoading: boolean = true;

  detailsdata: boolean = false;
  isLoadingImage: boolean = false;

  SuCMessage: boolean = true;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;

  current_date: string;
  current_username: string;
  current_laywerCode: string;

  lawyergroupdata: any = [];

  searchlistnews: any = [];

  is_admin: boolean = false;
  current_lawyerType: string;

  isBlockSearchList: boolean = false;
  isDue: any;
  isDueMgs: any;
  isDueModal: any;

  constructor(
    public rest: AppellatedivisionService,
    public lawyerinfo_api: LawyerinfoService,
    private SimpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private sanitizer: DomSanitizer
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }

  }

  ngOnInit() {
    this.getAdMaxDate();
    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin();

    this.current_lawyerType = this.app.getLawyerType();

    this.current_date = formatDate(new Date(), 'dd-MM-yyyy', 'en');
    //console.log(this.maxDate);
    if (this.maxDate != null) {
      this.current_date = this.maxDate;
      this.searchDate = this.maxDate;
    }
    if (!this.is_admin) {
      this.getLawyerInfoSadmin();

      this.getLawyerGroup();
    }

    this.getsearchlistnews();

    this.getSearchListBlockStatus();
    let oldDate = localStorage.getItem("oldDateAd");
    let oldDateAdId = localStorage.getItem("oldDateAdId");
    const dateOld  = new Date(oldDate)
    const currentDate  = new Date(this.current_date)
    
    if (dateOld < currentDate || oldDateAdId != this.current_laywerCode) {
      this.getLawyerPaymentIsDue(oldDate, oldDateAdId);
    } else {
      this.isDue = 0; this.isDueMgs = ""; this.isDueModal = false;
    }
    //  if(this.isDueModal) {
    //           let htmlCo=this.sanitizer.bypassSecurityTrustHtml(this.isDueMgs)
    // this.SimpleModalService.addModal(AlertComponent, { title: 'Due Massage', message: this.isDueMgs });
    //     }

  }



  modalOpen() {

    if (this.isDueModal) {
      // let htmlCo=this.sanitizer.bypassSecurityTrustHtml(this.isDueMgs)

      this.SimpleModalService.addModal(HtmlContentRenderModal, { title: 'বিল পরিশোধের অনুরোধ ।', message: this.isDueMgs });
    }
  }
  getAdMaxDate() {
    this.rest
      .getADMaxd()
      .subscribe(
        max => {

          this.rangeDate = new Date(max);
          this.searchDate = new Date(max);
          this.getCasetoday();


        }
      );

  }

  getCasetoday() {

    this.getSearchListBlockStatus();

    this.is_draft = 0;
    this.todaysdata = [];
    this.lawyerinfodata = [];
    this.previous_results = [];
    this.previous_results_datatime = [];
    this.previous_date = [];

    if (this.is_admin) {

      this.lawyergroupdata = [];
      this.input_lawyer_code = this.lawyerCode;

      if (this.input_lawyer_code === undefined || this.input_lawyer_code == "") {
        this.errorMsg2 = true;
        this.isLoading = true;
        return;
      } else if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg2 = false;
        this.errorMsg = true;
        this.isLoading = true;
        return;
      }

      this.current_date = formatDate(this.searchDate, 'dd-MM-yyyy', 'en');
      this.errorMsg = false;
      this.errorMsg2 = false;

      this.getLawyerGroup();

    } else {

      this.input_lawyer_code = this.current_laywerCode;

      if (this.searchDate === undefined || this.searchDate == null) {
        this.errorMsg = true;
        this.isLoading = true;
        return;
      }

      this.current_date = formatDate(this.searchDate, 'dd-MM-yyyy', 'en');
      this.errorMsg = false;
    }

    let postData = {
      lawyerCode: this.input_lawyer_code,
      searchDate: this.searchDate,
    }

    this.getLawyerInfo();

    this.isLoadingImage = true
    this.detailsdata = false

    this.SuCMessage = false;


    this.rest
      .getCasetoday(postData)
      .subscribe(
        todaysdata => {
          this.todaysdata = todaysdata
          this.isLoading = false
          this.errorMessage = ""
          this.isLoadingImage = false
          this.detailsdata = true
          this.SuCMessage = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
          this.isLoadingImage = false
          this.SuCMessage = false
        }
      );

    this.rest
      .getPreviousResult(postData)
      .subscribe(
        previous_results => {
          this.previous_results = previous_results
        }
      );

    this.rest
      .getPreviousResultDateTime(postData)
      .subscribe(
        previous_results_datatime => {
          this.previous_results_datatime = previous_results_datatime
        }
      );

    this.rest
      .getPreviousDate(postData)
      .subscribe(
        previous_date => {
          this.previous_date = previous_date
        }
      );
    this.rest
      .getDraftStatus(postData)
      .subscribe(
        is_draft => {
          this.is_draft = is_draft
          //console.log(is_draft);
        }
      );

  }

  getLawyerInfo() {
    let postData = {
      lawyerCode: this.input_lawyer_code
    }

    this.lawyerinfo_api
      .getLawyerinfo(postData)
      .subscribe(
        lawyerinfodata => {
          this.lawyerinfodata = lawyerinfodata
          this.isLoading = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }


  getLawyerPaymentIsDue(oldDate, oldDateHdId) {
    // let oldDate = localStorage.getItem("oldDateHd");
    //   let oldDateHdId = localStorage.getItem("oldDateHdId");
    let postData = {
      l_id: this.current_laywerCode
    }

    this.lawyerinfo_api
      .getLawyerPaymentIsDue(postData)
      .subscribe(
        resIsDue => {
          console.log(resIsDue)

          //  alert(resIsDue.message);
          this.isDue = resIsDue.isDue;
          this.isDueMgs = resIsDue.message;
          if (resIsDue.isDue == 1) {
            this.isDueModal = true
          }
          else { this.isDueModal = false }


          if (resIsDue.isDue == 1) {
            // alert(resIsDue.message);
            localStorage.setItem("oldDateAd", this.current_date);
            localStorage.setItem("oldDateAdId", this.current_laywerCode);


          } else if (oldDateHdId != this.current_laywerCode) {

            localStorage.removeItem("oldDateAdId");
            localStorage.removeItem("oldDateAd");

          }
          this.modalOpen()
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        },


      );

  }

  getLawyerInfoSadmin() {
    let postData = {
      lawyerCode: this.input_lawyer_code
    }

    this.lawyerinfo_api
      .getLawyerInfoSadmin(postData)
      .subscribe(
        lawyerinfodata => {
          this.lawyerinfodata = lawyerinfodata
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  getLawyerGroup() {
    let postData = {
      lawyerCode: this.is_admin ? this.input_lawyer_code : this.current_laywerCode
    }

    this.lawyerinfo_api
      .getLawyerGroup(postData)
      .subscribe(
        lawyergroupdata => {
          this.lawyergroupdata = lawyergroupdata
          this.isLoading = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }

  getsearchlistnews() {
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
      .getsearchlistnews(postData)
      .subscribe(
        searchlistnews => {
          // console.log(courtwisedata);
          this.searchlistnews = searchlistnews
        },
        error => {
          this.errorMessage = <any>error
        }
      );
  }

  getSearchListBlockStatus() {
    let postData = {
      id: 2
    }

    this.lawyerinfo_api
      .getSearchListBlockStatus(postData)
      .subscribe(
        isBlockSearchList => {
          this.isBlockSearchList = isBlockSearchList,
            this.isLoading = false
          //console.log("===="+isBlockSearchList[0].is_block_hd);
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }

}