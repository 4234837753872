import { Component, OnInit,  ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';

@Component({
  selector: 'app-bill-payment-reminder',
  templateUrl: './bill-payment-reminder.component.html',
  styleUrls: ['./bill-payment-reminder.component.css']
})
export class BillPaymentReminderComponent implements OnInit, AfterViewInit {
  @ViewChildren('sectionElement') sections: QueryList<ElementRef>
  billPaymentReminderList: []
  isLoading: boolean = false;
  billPaymentReminderAllList: [];
  isAllViews: boolean = false;
  constructor(
    private billInfoService: BillInformationService, 
      public app:AppComponent,
      private router : Router
    ) { 

      if (!this.app.loggedIn()) {
        this.router.navigate(['../']);
      } 
    }

  ngOnInit() {
    this.getPaymentReminder(1)

  }


  getPaymentReminder(id = 1) {
    this.isLoading = true;
    const lawyerCode = localStorage.getItem('loggedin_lawyercode');
    if (lawyerCode) {
      this.billInfoService.payment_Reminder_Message(+lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
          // this.billPaymentReminderList = data;
          

           if (data && data.length > 10) {
            this.billPaymentReminderList = data.slice(0, 10);
            this.billPaymentReminderAllList = data
           
          } else {
             this.billPaymentReminderList = data
          }
          this.isLoading = false;
          this.isAllViews = false;
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading =false
        }
      })
    }
  }


  ngAfterViewInit() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(this.onIntersection.bind(this), options);
    // console.log(observer)
    
    // this.sections.forEach(section => observer.observe(section.nativeElement));
  }

  onIntersection(entries: IntersectionObserverEntry[]) {
    // console.log(entries)
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const target = entry.target as HTMLElement;
        target.classList.add('in-view'); // Add custom styles when in view
        // Perform any additional actions when the section is in view
      } else {
        entry.target.classList.remove('in-view');
      }
    });
  }



  getBillInfoAllData() {
    this.billPaymentReminderAllList.slice(10).forEach(item => this.billPaymentReminderList.push(item));
    this.isAllViews = true
  }
}
