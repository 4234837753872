import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-bill-reminder-sadmin',
  templateUrl: './bill-reminder-sadmin.component.html',
  styleUrls: ['./bill-reminder-sadmin.component.css']
})
export class BillReminderSadminComponent implements OnInit {

  lawyerCode: number
  billPaymentReminderDataList: []
  isLoading: boolean = false;
  IsValidationErrorMessageShown = false; //:
  lawyerinfodata: any
  searchDate: any
  billPaymentReminderAllList: [];
  isAllViews: boolean = false
  constructor(private billInfoService: BillInformationService, private lawyerinfo_api: LawyerinfoService,
    public app: AppComponent,
    private router: Router
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit() {
    this.searchDate = new Date();

  }



  getLawyerInfo() {
    this.isLoading = true;
    let postData = {
      lawyerCode: this.lawyerCode
    }
    this.lawyerinfo_api
      .getLawyerinfo(postData)
      .subscribe({
        next: (data) => {
          // console.log(data);
          this.isLoading = false
          this.lawyerinfodata = data
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false
        }
      })
  }

  getPayment_Reminder_Message(id: number) {
    this.billPaymentReminderDataList = []
    if (!this.lawyerCode && typeof this.lawyerCode !== 'number') {
      this.IsValidationErrorMessageShown = true;
      return
    }
    this.isLoading = true
    if (this.lawyerCode) {
      this.billInfoService.payment_Reminder_Message(this.lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);

          // this.billPaymentReminderDataList = data;
         
          this.getLawyerInfo()

           if (data && data.length > 10) {
             this.billPaymentReminderDataList = data.slice(0, 10);
            this.billPaymentReminderAllList = data
           
          } else {
             this.billPaymentReminderDataList = data
           }
          this.isAllViews = false
           this.isLoading = false
        },
        error: (error) => {
          console.error('Error:', error);
          this.isLoading = false
        }
      })
    }
  }


  getBillInfoAllData() {
    this.billPaymentReminderAllList.slice(10).forEach(item => this.billPaymentReminderDataList.push(item));
    this.isAllViews = true
  }
}
