import { Component, OnInit } from '@angular/core';
import { AppellatedivisionService } from 'src/app/appellatedivision.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-ad-cause-list-search',
  templateUrl: './ad-cause-list-search.component.html',
  styleUrls: ['./ad-cause-list-search.component.css']
})
export class AdCauseListSearchComponent implements OnInit {

  selectedCaseType: string = '';
  selectedCaseTypeValue: number;

  selectChangeHandler(event: Event) {

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedCaseType = selectedOptions[selectedIndex].text;
    this.selectedCaseTypeValue = selectedOptions[selectedIndex].value;

    // console.log(this.selectedCaseType);
    // console.log(this.selectedCaseTypeValue);
  }


  casetypedata: any = [];
  causelistdata: any = [];
  causelisthistorydata: any = [];
  casetypeid: any;
  caseno: any;
  caseyear: any;

  errorMsg: boolean = false;
  errorMsg2: boolean = false;
  errorMsg3: boolean = false;
  errorMsgBlock: boolean = false;
  //errorMsg4: boolean = false;

  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = false;
  detailsdata: boolean = false;

  is_loggedin: boolean = false;
  is_admin: number = 0;

  constructor(
    public rest: AppellatedivisionService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCasetype();

    if (this.loggedIn()) {
      this.is_loggedin = true;

      localStorage.setItem('lastAction', Date.now().toString());

    } else {
      this.is_loggedin = false;
    }
  }


  getCasetype() {
    let postData = {
      casetypeid: this.casetypeid,
    }

    this.rest
      .getCasetype(postData)
      .subscribe(
        casetypedata => {

          casetypedata.filter(csType=>{
            if (csType.type_name !== '(') {
              this.casetypedata.push(csType)
              this.isLoading = true
            }
          })
   
    
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }


  getlogincheck(){
    let postData = {
      casetypeid: this.casetypeid,
      caseno: this.caseno,
      caseyear: this.caseyear,
    }

    if (this.casetypeid === undefined || this.casetypeid == "" || this.casetypeid == null) {
      this.errorMsg2 = true;
      return;
    }
    else if (this.caseno === undefined || this.caseno == "" || this.caseno == null) {
      this.errorMsg2 = false;
      this.errorMsg = true;
      return;
    }
    else if (this.caseyear === undefined || this.caseyear == "" || this.caseyear == null || this.caseyear.length != 4) {
      this.errorMsg = false;
      this.errorMsg2 = false;
      this.errorMsg3 = true;
      return;
    }
    /* 
    else if (this.caseyear.value.length != 4){
      this.errorMsg  = false;
      this.errorMsg2 = false;
      this.errorMsg3 = false;
      this.errorMsg4 = true;
      return;
    }*/

    //this.errorMsg4 = false;
    this.errorMsg3 = false;
    this.errorMsg2 = false;
    this.errorMsg = false;


    //console.log(postData);

    window.scrollTo({
      top: 700,
      left: 0,
      behavior: 'smooth'
    });

    this.isLoadingImage = true
    this.detailsdata = false

    this.rest
      .getCauselistad(postData)
      .subscribe(
        causelistdata => {
          // console.log(courtwisedata);
          this.causelistdata = causelistdata
          this.isLoading = false
          this.isLoadingImage = false
          this.detailsdata = true
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
          this.isLoadingImage = false
        }
      );
  }


  getCauselistad() {
    var date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    var searchByDate = JSON.parse(localStorage.getItem("searchByDate"));
    var adStatus = JSON.parse(localStorage.getItem("adStatus"));


    if (this.loggedIn()) {
      this.getlogincheck();
    }
    else{
      if (searchByDate === date && adStatus === null) {
        var adStatus1 = 1;
        localStorage.setItem("adStatus", JSON.stringify(adStatus1));
        this.getlogincheck();
      } 
      else{
        this.errorMsgBlock=true;
      }
    }
  }


  getCauselisthistoryad() {
    let postData = {
      casetypeid: this.casetypeid,
      caseno: this.caseno,
      caseyear: this.caseyear,
    }
    //console.log(postData);

    this.rest
      .getCauselisthistoryad(postData)
      .subscribe(
        causelisthistorydata => {
          // console.log(courtwisedata);
          this.causelisthistorydata = causelisthistorydata
          this.isLoading = false
        },
        error => {
          this.errorMessage = <any>error
          this.isLoading = false
        }
      );
  }

  loggedIn(): boolean {
    return localStorage.getItem('access_token') !== null;
  }

}
