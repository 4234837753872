import { Component, OnInit } from "@angular/core";
import { AppellatedivisionService } from "../../../appellatedivision.service";
import { LawyerinfoService } from "../../../lawyerinfo.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";
@Component({
  selector: "app-total-case-list-appelate",
  templateUrl: "./total-case-list-appelate.component.html",
  styleUrls: ["./total-case-list-appelate.component.css"],
})
export class TotalCaseListAppelateComponent implements OnInit {
  adminBdlawserviceFile = environment.adminBdlawserviceFile;
  totalcaselistaddata: any = [];
  totalcaselistaddatacount: any = [];
  totalcaselistaddetailsdata: any = [];
  totalcaselisttwdata: any = [];
  totalcasesummarylwdata: any = [];
  noResultFound = false;
  remarksData: any = [];

  remarks: any;

  search_code: any;

  errorMessage: string;
  isLoading: boolean = true;

  status: boolean = false;
  details: boolean = false;
  Totaldetails: boolean = false;

  sadmin: boolean = false;
  ssadmin: boolean = false;
  detailsdata: boolean = false;
  isLoadingImage: boolean = false;
  isLoadingImage2: boolean = false;
  isLoadingImage3: boolean = false;

  current_username: string;
  current_laywerCode: string;
  is_admin: boolean = false;

  lawyerCode: any;
  lawyergroupdata: any = [];

  current_lawyerType: string;

  editRow: boolean = false;
  autoId: any;

  lawyer_ids: any;
  case_type_ids: any;
  case_nos: any;
  case_years: any;
  isNotUser: boolean = false;
  islawyerSearch = false
  totalCase: any[] = []
  totalCasePdfData: any =[]
  caseTypeName = null
remarkPermission: number = 0
  constructor(
    public rest: AppellatedivisionService,
    private route: ActivatedRoute,
    public lawyerinfo_api: LawyerinfoService,
    private router: Router,
    public app: AppComponent,

  ) {
    if (!this.app.loggedIn()) {
      this.router.navigate(["../"]);
    }
  }

  ngOnInit() {
    this.current_username = this.app.getUserName();
    this.current_laywerCode = this.app.getLawyerCode();
    this.is_admin = this.app.isAdmin();

    this.current_lawyerType = this.app.getLawyerType();

    this.getLawyerGroup();

    if (!this.is_admin) {
      this.search_code = this.current_laywerCode;
      this.getTotalcaselistad();
      this.getTotalcaselistadcount();
    }

    if (this.is_admin) {
      // this.getTotalcaselisthighcourt();
      this.getTotalcasesummaryLWSadmin();
    }

    this.getRemark()
  }


  getRemark() {


    this.lawyerinfo_api.getRemarkPermission({
      lawyer_code: this.search_code
    }).subscribe({
      next: (res) => {
        console.log('results', Number(res.results[0].is_permission))
        this.remarkPermission = Number(res.results[0].is_permission)
        console.log('remarkPermission',this.remarkPermission)
      },
      error(err) {
        console.error('something wrong occurred: ' + err);
      },
    })
  }


  pdfDonwload() {

  
    // Add content to the PDF
    const casekey = Object.keys(this.totalCasePdfData)
    .map((caseTypeKey) => {
      // Retrieve the case array for this case type
      const caseArray = this.totalCasePdfData[caseTypeKey];
  
      // Generate rows for each case in the array
      const caseRows = caseArray
        .map((caseItem, index) => {
          return `
            <tr>
            <td>
              ${caseItem && caseItem.type_name != null ? caseItem.type_name : ''}
            </td>
            <td>
              ${caseItem && caseItem.case_no != null ? caseItem.case_no : ''}/${caseItem && caseItem.case_year != null ? caseItem.case_year : ''}
            </td>
            <td>
              ${caseItem && caseItem.pr == 1 ? 'Petitioner' : 'Respondent'}
            </td>
            <td style="min-width: 100px !important;">
              ${caseItem && caseItem.entry_date != null ? formatDate(caseItem.entry_date, 'dd-MM-yyyy', 'en') : ''}
            </td>
            ${
              this.remarkPermission == 1
                ? `<td style="max-width: 150px !important; word-wrap: break-word;">
                    ${caseItem && caseItem.remarks != null ? caseItem.remarks : ' '}
                  </td>`
                : ''
            }
          </tr>

          `;
        })
        .join('');
  
      // Wrap the rows for the current case type
      return `
      ${caseRows}
      <tr style="background: #dddddd; font-weight: bold;">
       <td colspan="${this.remarkPermission == 1 ? (caseArray && caseArray.length != null ? 5 : 4) : 4}" 
          style="color: #000; text-align: left; font-weight: bold">
        ${caseTypeKey != null ? caseTypeKey : ''}: ${caseArray && caseArray.length != null ? caseArray.length : 0}
      </td>

      </tr>
      `;
    })
    .join(''); // Combine all rows into a single string
  
  // Function to split rows into pages
  
  
  
  // Generate the HTML for all pages
  const pageHtml = `
  <html>
    <head>
      <style>
        @page {
          width: 210mm;
          margin: 0.50inc;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          page-break-before: always;
        }
        body {
          font-family: Arial, sans-serif;
        }
        .header,
        .footer {
          text-align: center;
        }
        .pdf-table {
          width: 100%;
          border-collapse: collapse;
          
        }
        .pdf-table th,
        .pdf-table td {
          border: 1px solid #d5d5d5;
          padding: 4px;
          text-align: left;
        }
        .header-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ada5a5;
          padding: 0px 0;
          height: 31px;
        }
        .header-top .general-code {
          margin-left: 0px;
          color: #000;
        }
        .header-top .header-date {
          margin-right: 0px;
          color: #000;
        }
        table tr th {
          padding: 8px 12px;
          border: 1px solid #d5d5d5;
          background-color: #dddddd;
          color: white;
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          color: #000
        }
        table tbody tr td {
          padding: 8px 12px;
          border: 1px solid #ddd;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          
        }
        // tr:nth-child(even) {
        //   background-color: #dddddd;
        // }
        .pages {
          width: 210mm;
          background: #fff;
        }
        .footer-design {
          display: flex;
          text-align: left;
          justify-content: space-between;
          line-height: 20px;
          margin-top: 15px;
        }
      </style>
    </head>
    <body>
     <div class="pages">
              <div class="header">
                <div class="header-top">
                  <div>
                     <h2 class="general-code">${this.totalcaselistaddata[0].lawyer_group == 1 ? 'General': 'Special'}  Code: ${
                      this.totalcaselistaddata[0].lawyer_id
                    } </h2>
                  </div>
                  <div>
                    <p>(Print Time: ${formatDate(
                      new Date(),
                      'dd-MM-yyyy',
                      'en'
                    )} At ${new Date().toLocaleTimeString()})</p>
                  </div>
                  <div>
                    <h4 class="header-date">Date: ${formatDate(
                      new Date(),
                      'dd-MM-yyyy',
                      'en'
                    )}</h4>
                  </div>
                </div>
               <div style='text-align: left; margin: 15px 0; 0 height : 90px' >
                  <h2 style='margin: 0; padding: 0; font-size: 20px'>${
                    this.totalcaselistaddata[0].lawyer_name
                  }</h2>
                  <h5 style='margin: 0; padding: 8px 0px; font-size: 16px'>${
                    this.totalcaselistaddata[0].address
                  }</h5>
                  <p style='margin: 0; padding: 0'>${
                    this.totalcaselistaddata[0].phone
                  }</p>
                </div>
             <div style='text-align: center; margin: 15px 0 ; background: #dddddd; color: #000 ' >
                  
                  <p style='margin: 0; padding: 10px 0px; font-size: 22px; font-weight: bold;'>   ${this.caseTypeName !== null ?  `   (AD) Total ${this.caseTypeName }   `: 'Appellate Division Total Cases' }  :  ${
                    this.totalCase.length
                  }</p>
                </div>
               
              </div>
              <table class="pdf-table" style="width: 100%; border-collapse: collapse; ">
                <thead>
                  <tr>
                    <th>Case Type</th>
                    <th>Case No</th>
                    <th>P/R</th>
                    <th style="min-width: 100px !important ;">Entry Date</th>
                    ${
                      this.remarkPermission == 1 ? ' <th style="max-width: 150px !important ;">Remarks</th>' : ''
                    }
                  </tr>
                </thead>
                <tbody>
                  ${casekey}
                </tbody>
              </table>


              


                 <div style='text-align: center; margin: 15px 0 ; background: #dddddd; color: #000 ' >
                  
                  <p style='margin: 0; padding: 10px 0px; font-size: 22px; font-weight: bold;'>   ${this.caseTypeName !== null ?  `   (AD) Total ${this.caseTypeName }   `: 'Appellate Division Total Cases' }  :  ${
                    this.totalCase.length
                  }</p>
                </div>
              <div class="footer">
                <div class="footer-design">
                  <div style="padding-right: 32px;">
                    <p style="font-size: 14px">Service Provide by : <span style="font-size: 14px; font-weight: bold; padding-right: 4;">SIDDIQUE ENTERPRISE </span> Room No. 45 and 60 (G.F), Phone: 01819-49 09 29, 01772-72 78 78
                   <br/> E-mail : bdlawservice@gmail.com, Web: <b>www.bdlawservice.com & www.bdcourt.com</b> </p>
                  </div>
              
                </div>
              </div>
            </div>
    </body>
  </html>
  `;

const iframe = document.createElement('iframe');
iframe.style.position = 'absolute';
iframe.style.top = '-9999px';
document.body.appendChild(iframe);

const doc = iframe.contentWindow.document || iframe.contentDocument;
if (!doc) {
  console.error('Failed to access iframe document.');
  return;
}

doc.open();
doc.write(pageHtml)
doc.close()

iframe.onload = () => {
  iframe.contentWindow.focus();
  iframe.contentWindow.print();
};


// window.document.write(pageHtml)
  }


//   pdfDonwload() {
//     const casekey = Object.keys(this.totalCasePdfData)
//   .map((caseTypeKey) => {
//     // Retrieve the case array for this case type
//     const caseArray = this.totalCasePdfData[caseTypeKey];

//     // Generate rows for each case in the array
//     const caseRows = caseArray
//       .map((caseItem, index) => {
//         return `
//           <tr>
//             <td>${index}</td>
//             <td>${caseItem.type_name}</td>
//             <td>${caseItem.case_no}/${caseItem.case_year}</td>
//             <td>${caseItem.pr == 1 ? 'Petitioner' : 'Respondent'}</td>
//             <td>${caseItem.entry_date}</td>
//              ${
//                this.remarkPermission == 1
//                  ? `<td>${caseItem.remarks === null ? ' ' : caseItem.remarks}</td>`
//                  : ''
//              }
//           </tr>
//         `;
//       })
//       .join('');

//     // Wrap the rows for the current case type
//     return `
//       <tr style="background: #093070; font-weight: bold;">
//         <td colspan="${this.remarkPermission == 1 ? 5 : 4}" style='color: white; text-align: center;'>${caseTypeKey}: ${caseTypeKey.length}</td>
//       </tr>
//       ${caseRows}
//     `;
//   })
//   .join(''); // Combine all rows into a single string

// // Function to split rows into pages
// function splitIntoPages(rows, firstPageRows = 15, rowsPerPage = 39) {
//   const pages = [];
//   let startIndex = 0;
//   pages.push(rows.slice(0, firstPageRows)); // First page
//   startIndex += firstPageRows;
//   while (startIndex < rows.length) {
//     pages.push(rows.slice(startIndex, startIndex + rowsPerPage));
//     startIndex += rowsPerPage;
//   }
//   return pages;
// }

// // Split the case data into pages
// const pages = splitIntoPages(
//   casekey.split('</tr>').filter((row) => row).map((row) => row + '</tr>')
// );

// // Generate the HTML for all pages
// const pageHtml = `
// <html>
//   <head>
//     <style>
//       @page {
//         width: 210mm;
//         margin: 0px auto;
//         background: #fff;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
//         page-break-before: always;
//         height: 297mm;
//       }
//       body {
//         font-family: Arial, sans-serif;
//       }
//       .header,
//       .footer {
//         text-align: center;
//       }
//       .pdf-table {
//         width: 100%;
//         border-collapse: collapse;
//         margin: 0 auto;
//       }
//       .pdf-table th,
//       .pdf-table td {
//         border: 1px solid #d5d5d5;
//         padding: 4px;
//         text-align: left;
//       }
//       .header-top {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         border-bottom: 1px solid #ada5a5;
//         padding: 0px 0;
//         height: 31px;
//       }
//       .header-top .general-code {
//         margin-left: 0px;
//         color: #4876A2;
//       }
//       .header-top .header-date {
//         margin-right: 0px;
//         color: #4876A2;
//       }
//       table tr th {
//         padding: 8px 12px;
//         border: 1px solid #d5d5d5;
//         background-color: #4876A2;
//         color: white;
//         text-align: left;
//         font-size: 15px;
//         font-weight: 500;
//       }
//       table tbody tr td {
//         padding: 8px 12px;
//         border: 1px solid #d5d5d5;
//         text-align: center;
//         font-size: 15px;
//         font-weight: 500;
//       }
//       tr:nth-child(even) {
//         background-color: #dddddd;
//       }
//       .pages {
//         width: 210mm;
//         padding: 11mm;
//         background: #fff;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
//         margin: 20 auto;
//         height: 210mm;
//       }
//         .footer{
//         height: 10mm;
//         }
//       .footer-design {
//         display: flex;
//         text-align: left;
//         justify-content: space-between;
//         line-height: 20px;
//         margin-top: 15px;
//       }
//     </style>
//   </head>
//   <body>
//     ${pages
//       .map((pageData, pageIndex) => {
//         const pageNumber = pageIndex + 1;
//         const totalPages = pages.length;
//         return `
//           <div class="pages">
//             <div class="header">
//               <div class="header-top">
//                 <div>
//                   <h4 class="general-code">General Code: ${
//                     this.totalcaselistaddata[0].lawyer_id
//                   } </h4>
//                 </div>
//                 <div>
//                   <p>(Print Time: ${new Date().toLocaleString()})</p>
//                 </div>
//                 <div>
//                   <h4 class="header-date">Date: ${formatDate(
//                     new Date(),
//                     'dd-MM-yyyy',
//                     'en'
//                   )}</h4>
//                 </div>
//               </div>
//               ${
//                 pageIndex === 0
//                   ? `
//               <div style='text-align: left; margin: 15px 0; height : 90px' >
//                 <h2 style='margin: 0; padding: 0; font-size: 20px'>${
//                   this.totalcaselistaddata[0].lawyer_name
//                 }</h2>
//                 <h5 style='margin: 0; padding: 8px 0px; font-size: 16px'>${
//                   this.totalcaselistaddata[0].address
//                 }</h5>
//                 <p style='margin: 0; padding: 0'>${
//                   this.totalcaselistaddata[0].phone
//                 }</p>
//               </div>`
//                   : ''
//               }
//             </div>
//             <table class="pdf-table" style="width: 100%; border-collapse: collapse;">
//               <thead>
//                 <tr>
//                   <th>Case Type</th>
//                   <th>Case No</th>
//                   <th>P/R</th>
//                   <th>Entry Date</th>
//                   ${
//                     this.remarkPermission == 1 ? ' <th>Remarks</th>' : ''
//                   }
//                 </tr>
//               </thead>
//               <tbody>
//                 ${pageData.join('')}
//               </tbody>
//             </table>
//             <div class="footer">
//               <div class="footer-design">
//                 <div style="padding-right: 32px;">
//                   <p style="font-size: 13px">Service Provide by : <span style="font-size: 15px; font-weight: bold; padding-right: 4;">SIDDIQUE ENTERPRISE </span> Room No. 45 and 60 (G.F), Phone: 01819-49 09 29, 01772-72 78 78
//                   E-mail : bdlawservice@gmail.com, Web: <b>www.bdlawservice.com & www.bdcourt.com</b> </p>
//                 </div>
//                 <div style="width: 200px">
//                   <p style="font-size: 14px;"> <b>Page ${pageNumber} of ${totalPages}</b></p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         `;
//       })
//       .join('')}
//   </body>
// </html>
// `;
// // const iframe = document.createElement('iframe');
// // iframe.style.position = 'absolute';
// // iframe.style.top = '-9999px';
// // document.body.appendChild(iframe);

// // const doc = iframe.contentWindow.document || iframe.contentDocument;
// // if (!doc) {
// //   console.error('Failed to access iframe document.');
// //   return;
// // }

// // doc.open();
// // doc.write(pageHtml)
// // doc.close()

// // iframe.onload = () => {
// //   iframe.contentWindow.focus();
// //   iframe.contentWindow.print();
// // };


// window.document.write(pageHtml)
//   }

  getTotalcaselistad() {
    this.islawyerSearch = true
    let postData = {
      search_code: this.search_code,
    };

    // console.log(postData);
    this.totalcaselistaddata = [];
    this.isLoadingImage = true;
    this.noResultFound = false;
    this.lawyerinfo_api.getLawyerGroup({
      lawyerCode: this.search_code,
    })
      .subscribe(
        (lawyergroupdata) => {
          this.isLoadingImage = false;
          if (lawyergroupdata.hasOwnProperty("ad_user")) {
            this.isNotUser = false
            if (lawyergroupdata.ad_user != 0) {
              this.lawyergroupdata = lawyergroupdata;
              this.rest.getTotalcaselistad(postData).subscribe(
                (totalcaselistaddata) => {
                  // console.log('totalcaselistaddata', totalcaselistaddata)
                  if (totalcaselistaddata.length > 0) {
                    this.totalcaselistaddata = totalcaselistaddata;
                    this.noResultFound = false;
                  } else {
                    this.totalcaselistaddata = [];
                    this.totalcaselistaddata = [];
                    this.noResultFound = true;
                  }

                  this.isLoading = false;
                  this.status = true;
                  this.Totaldetails = false;
                  this.details = false;
                  this.isLoadingImage = false;
                },
                (error) => {
                  this.errorMessage = <any>error;
                  this.isLoading = false;
                  this.isLoadingImage = false;
                  this.lawyergroupdata = {
                    ad_user: 1
                  }
                }
              );
            } else {
              this.lawyergroupdata = lawyergroupdata;
            }
          } else {
            this.isNotUser = true
            this.isLoading = false;
            this.isLoadingImage = false;
            this.lawyergroupdata = {
              ad_user: 1
            }

          }


        },
        (error) => {
          this.errorMessage = <any>error;
          this.isLoading = false;
          this.isLoadingImage = false;
        }
      );
  }


  getTotalcaselistadcount() {
    let postData = {
      search_code: this.search_code,
    };

    // console.log(postData);
    this.totalcaselistaddatacount = [];

    this.rest.getTotalcaselistadcount(postData).subscribe(
      (totalcaselistaddatacount) => {
        this.totalcaselistaddatacount = totalcaselistaddatacount;
        this.isLoading = false;
        this.status = true;

        console.log(this.totalcaselistaddatacount);
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  getTotalcaselistaddetails(l_id, case_type_id, type_name) {
    // console.log(l_id);
    // console.log(case_type_id);
    // console.log(type_name);

    //window.scroll(0,520);

    this.autoId = null;

    window.scrollTo({
      top: 510,
      left: 0,
      behavior: "smooth",
    });

    this.isLoadingImage2 = true;
    this.detailsdata = false;

    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    };

    //console.log(postData);

    // this.isLoadingImage = true
    this.caseTypeName = type_name
    const newArray: { [key: string]: any[] } = {};
    this.totalcaselistaddetailsdata = [];

    this.rest.getTotalcaselistaddetails(postData).subscribe(
      (totalcaselistaddetailsdata) => {
        // console.log(totalcaselistaddetailsdata)
        totalcaselistaddetailsdata.forEach((item: any) => {
          const key = type_name; 
          if (!newArray[key]) {
            newArray[key] = [];
          }
          newArray[key].push(item); 
        });

    this.totalCasePdfData = newArray

    this.totalCase = totalcaselistaddetailsdata

        this.totalCase = totalcaselistaddetailsdata
        this.totalcaselistaddetailsdata = totalcaselistaddetailsdata;
        
        this.isLoading = false;
        this.details = true;
        this.Totaldetails = false;
        this.isLoadingImage2 = false;
        this.detailsdata = true;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
        this.isLoadingImage2 = false;
      }
    );
  }

  getTotalcaselistCountTW(l_id, case_type_id) {
    //console.log(l_id);
    //console.log(case_type_id);
    let postData = {
      l_id: l_id,
      case_type_id: case_type_id,
    };

    //console.log(postData);
    this.totalcaselisttwdata = [];

    this.rest.getTotalcaselistCountTW(postData).subscribe(
      (totalcaselisttwdata) => {
        this.totalcaselisttwdata = totalcaselisttwdata;
        this.isLoading = false;
        this.details = true;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  getTotalcasesummaryLW(l_id) {
    //console.log(l_id);
    let postData = {
      l_id: l_id,
    };

    this.isLoadingImage3 = true;
    this.detailsdata = false;

    window.scrollTo({
      top: 510,
      left: 0,
      behavior: "smooth",
    });
    //console.log(postData);

    this.totalcasesummarylwdata = [];

    this.rest.getTotalcasesummaryLW(postData).subscribe(
      (totalcasesummarylwdata) => {
        this.caseTypeName = null
        // const combinedArray = Object.values(totalcasesummarylwdata).flat();
        const combinedArray = (Object.values(totalcasesummarylwdata) as any[][]).reduce((accumulator, currentArray) => {
          return [...accumulator, ...currentArray];
        }, []);

        this.totalCase = combinedArray
        // console.log('totalcasesummarylwdata', this.totalCase);
        this.totalcasesummarylwdata = totalcasesummarylwdata;
        this.totalCasePdfData = totalcasesummarylwdata;
        this.isLoading = false;
        this.status = true;
        this.Totaldetails = true;
        this.details = false;
        this.isLoadingImage3 = false;
        this.detailsdata = true;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
        this.status = false;
        this.isLoadingImage3 = false;
      }
    );
  }

  getTotalcasesummaryLWSadmin() {
    let postData = {
      l_id: this.search_code,
    };

    //console.log(postData);
    this.totalcasesummarylwdata = [];

    this.rest.getTotalcasesummaryLWSadmin(postData).subscribe(
      (totalcasesummarylwdata) => {
        //console.log(totalcasesummarylwdata);
        const combinedArray = (Object.values(totalcasesummarylwdata) as any[][]).reduce((accumulator, currentArray) => {
          return [...accumulator, ...currentArray];
        }, []);
        this.totalCase = combinedArray
        this.totalcasesummarylwdata = totalcasesummarylwdata;
        this.totalCasePdfData = totalcasesummarylwdata;
        
        this.isLoading = false;
        this.status = true;
        this.ssadmin = true;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  getLawyerGroup() {
    let postData = {
      lawyerCode: this.current_laywerCode,
    };

    this.lawyergroupdata = [];

    this.lawyerinfo_api.getLawyerGroup(postData).subscribe(
      (lawyergroupdata) => {
        this.lawyergroupdata = lawyergroupdata;
        this.isLoading = false;
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  editRemarks(
    autoIds,
    lawyer_id,
    lawyer_auto_id,
    case_type_id,
    case_no,
    case_year,
    remarks
  ) {
    this.editRow = true;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;
    this.remarks = remarks;
  }

  addRemarks(
    autoIds,
    lawyer_id,
    lawyer_auto_id,
    case_type_id,
    case_no,
    case_year
  ) {
    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks: this.remarks,
    };

    this.remarksData = [];
    //console.log(this.remarks);

    this.rest.saveOrEditAdRemarks(postData).subscribe(
      (remarksData) => {
        this.remarksData = remarksData;
        this.isLoading = false;
        this.details = true;
        this.getTotalcaselistaddetails(lawyer_id, case_type_id, '');
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }

  addRemarksForDetails(
    autoIds,
    lawyer_id,
    lawyer_auto_id,
    case_type_id,
    case_no,
    case_year
  ) {
    this.editRow = false;
    this.autoId = autoIds;

    this.lawyer_ids = lawyer_auto_id;
    this.case_type_ids = case_type_id;
    this.case_nos = case_no;
    this.case_years = case_year;

    let postData = {
      lawyer_id: lawyer_auto_id,
      case_type_id: case_type_id,
      case_no: case_no,
      case_year: case_year,
      remarks: this.remarks,
    };

    //console.log(this.remarks);

    this.remarksData = [];

    this.rest.saveOrEditAdRemarks(postData).subscribe(
      (remarksData) => {
        this.remarksData = remarksData;
        this.isLoading = false;
        this.details = true;
        this.getTotalcasesummaryLW(lawyer_id);
      },
      (error) => {
        this.errorMessage = <any>error;
        this.isLoading = false;
      }
    );
  }
}
