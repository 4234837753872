import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { BillInformationService } from 'src/app/bill-information.service';
import { LawyerinfoService } from 'src/app/lawyerinfo.service';

@Component({
  selector: 'app-bill-history-for-admin',
  templateUrl: './bill-history-for-admin.component.html',
  styleUrls: ['./bill-history-for-admin.component.css']
})
export class BillHistoryForAdminComponent implements OnInit {

  lawyerCode: number
  billHistoryForAdminDataList: []
  isLoading: boolean = false;
  IsValidationErrorMessageShown = false; //:
  searchDate: any
  lawyerinfodata: any
  paymentInfoData:  any [] = [];
  erroMassage = ''
  paymentInfoAllDataList: [];
  isAllViews: boolean = false
  constructor(private billInfoService: BillInformationService,
    public app: AppComponent,
    private router: Router
  ) {

    if (!this.app.loggedIn()) {
      this.router.navigate(['../']);
    }
  }

  ngOnInit() {
    this.searchDate = new Date();

  }



  

  getbillhistoryForAdmin(id: number) {
    if (!this.lawyerCode && typeof this.lawyerCode !== 'number') {
      this.IsValidationErrorMessageShown = true;
      return
    }
    this.isLoading = true
    if (this.lawyerCode) {
      this.billInfoService.getBitHistoryForAdmin(this.lawyerCode, id).subscribe({
        next: (data) => {
          // console.log(data);
         

          if (data.paymentInfo && data.paymentInfo.length > 10) {
         
            this.paymentInfoData = data.paymentInfo.slice(0, 10);
            this.paymentInfoAllDataList = data.paymentInfo
           
          } else {
             this.paymentInfoData = data.paymentInfo
           }
          // console.log(data.paymentInfo );
          // this.paymentInfoData = data.paymentInfo
           this.billHistoryForAdminDataList = data;
          this.lawyerinfodata = data.lawyerInfo
          this.isLoading = false
          this.isAllViews = false
          this.erroMassage  = ''
    
        },
        error: (error) => {
          // console.log('Error:', error.error.message);
          this.isLoading = false
          this.lawyerinfodata = []
          this.paymentInfoData = []
          this.erroMassage  = error.error.message
        }
      })
    }
  }



getBillInfoAllData() {
    this.paymentInfoAllDataList.slice(10).forEach(item => this.paymentInfoData.push(item));
    this.isAllViews = true
  }

}
